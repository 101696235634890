import { HiOutlineMail } from "react-icons/hi";
import { IoCall, IoLocation } from "react-icons/io5";
import "./App.css";
import { useEffect, useState } from "react";

function App() {
  const [showImage, setShowImage] = useState(0);
  const images = ["assets/slide_1.jpg", "assets/pexels-pixasquare-1115804.jpg"];

  useEffect(() => {
    const interval = setInterval(() => {
      setShowImage((prev) => (prev + 1) % images.length);
    }, 7000); // Change image every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [images.length]);

  return (
    <div className="App">
      <header className="  flex flex-col justify-center lines   ">
        {/* Animated Lines */}
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>

        {/* Background Image */}
        <img
          className=" background-image "
          src={images[0]}
          alt="background"
          style={{ opacity: showImage === 0 ? 1 : 0 }}
        />
        <img
          className=" background-image"
          src={images[1]}
          alt="background"
          style={{ opacity: showImage === 1 ? 1 : 0 }}
        />

        {/* <div className="line"></div>   */}

        {/* Foreground Content */}
        <div className="container self-center p-10">
          <div className="content-box p-5 backdrop-blur-lg bg-white/30 flex flex-col content-center items-center justify-center rounded-3xl">
            <div className="text-secondary uppercase  gap-y-7 z-10 relative flex flex-col items-center">
              <img src="assets\SVG (1).png" style={{height:200, width:200}}></img>
              <p className="text-6xl text-center  md:text-6xl font-extrabold bg-clip-text text-transparent bg-[linear-gradient(to_right,theme(colors.darkblue),theme(colors.lightblue),theme(colors.primary))] bg-[length:200%_auto] animate-gradient">
              Launching Soon!
              </p>
              {/* <div className="text-center">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Veritatis, dolorum.
              </div> */}
            </div>
            <div className="mt-24  text-darkblue flex flex-row flex-wrap justify-center z-10 relative">
              <div className="flex flex-col flex-wrap p-10 ">
                <IoLocation className="self-center  " size={30} />
                <p>718, CITY GATE, VISHALA, AHMEDABAD</p>
              </div>
              <div className="flex flex-col flex-wrap font-mono p-10">
                <IoCall className="self-center" size={30} />
                <p>+91 9825038360</p>
              </div>
              {/* <div className="flex flex-col flex-wrap font-mono p-10">
                <HiOutlineMail className="self-center" size={30} />
                <p>xyz@gmail.com</p>
              </div> */}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
